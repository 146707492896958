// Color Variables
$primary-color: #f7463a;
$secondary-color: #07085d;
$text-color: #6c6c86;
$text-color-dark: #000;
$body-color: #fff;
$border-color: #d2d2e2;
$black: #000;
$white: #fff;
$primary-gradient: linear-gradient( 37deg, rgb(180,62,121) 1%, rgb(247,70,58) 100%);


// Font Variables
$primary-font: 'Poppins', sans-serif;
$secondary-font: 'Noto Sans', sans-serif;
$icon-font: 'themify';