body {
  background-color: $body-color;
  overflow-x: hidden;
}

::selection {
  background: lighten($color: $primary-color, $amount: 10);
  color: $white;
}

/* preloader */

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

ol,
ul {
  list-style-type: none;
  margin: 0px;
}

img {
  vertical-align: middle;
  border: 0;
}

a {
  color: $primary-color;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

.hover-text-underline:hover {
  text-decoration: underline;
}

a,
button,
select {
  cursor: pointer;
  transition: .2s ease;

  &:focus {
    outline: 0;
  }
}

a:hover {
  color: $primary-color;
}

.slick-slide {
  outline: 0;
}

.section {
  padding-top: 110px;
  padding-bottom: 100px;

  &-sm {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &-lg {
    padding-top: 170px;
    padding-bottom: 170px;
  }
}

.section-border {
  height: 9px;
  width: 220px;
  background: $primary-gradient;
  border-radius: 5px;
  margin: 40px auto 90px;
}

.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-contain {
  background-size: contain;
  background-position: center right;
  background-repeat: no-repeat;
}

.border-primary {
  border-color: $border-color !important;
}

/* overlay */

.overlay {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: $black;
    opacity: .8;
  }

  &-primary {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: $primary-color;
      opacity: .8;
    }
  }

  &-secondary {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: $secondary-color;
      opacity: .8;
    }
  }

  &-secondary-half {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(to right, $secondary-color 60%, transparent);
    }
  }

  &-image {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
}

.outline-0 {
  outline: 0 !important;
}

.d-unset {
  display: unset !important;
}

.bg-primary {
  background: $primary-color !important;
}

.bg-secondary {
  background: $secondary-color !important;
}

.text-primary {
  color: $primary-color !important;
}

.text-secondary {
  color: $secondary-color !important;
}

.text-gradient-primary {
  background: $primary-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-light {
  color: #b7b8f1 !important;
}

.text-color {
  color: $text-color !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.zindex-1 {
  z-index: 1;
}

.top-100 {
  top: 100px;
}

.overflow-hidden {
  overflow: hidden !important;
}

.font-primary {
  font-family: $primary-font !important;
}

.font-secondary {
  font-family: $secondary-font !important;
}

.shadow {
  box-shadow: 0px 46px 65px 0px rgba(181, 188, 236, 0.16) !important;
}

.bg-gradient-primary {
  background: $primary-gradient !important;
}

.transition {
  transition: .3s ease;
}

.vertical-align-middle {
  vertical-align: middle;
}

/* form control */
.form-control {
  height: 50px;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid $border-color;

  &:focus {
    box-shadow: none !important;
    border-color: $primary-color;
  }
}

textarea.form-control {
  height: 150px;
}

/* /form control */

/* page-title */
.page-title {
  padding: 170px 0 190px;
}

/* /page-title */


/* list style */
.list-styled {
  padding-left: 0;

  li {
    position: relative;
    padding-left: 25px;
    list-style-type: none;

    &::before {
      position: absolute;
      content: "\e65d";
      font-family: $icon-font;
      font-size: 14px;
      left: 0;
      top: 0px;
      color: $secondary-color;
      transition: .3s ease;
    }
  }
}

/* social icons */
.social-icons {
  a {
    display: block;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    line-height: 50px;
    background: $secondary-color;
    color: $white;
    text-align: center;
  }
}

/* /social icons */