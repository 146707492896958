.navigation {
  padding: 20px 100px;
  transition: .3s ease;

  &.nav-bg {
    background-color: $secondary-color;
    padding: 15px 100px;

    @include desktop {
      padding: 15px 20px;
    }
  }

  @include desktop {
    padding: 20px;
    background: $secondary-color;
  }
}

.navbar {
  .nav-item {
    .nav-link {
      font-family: $primary-font;
      padding: 15px;
      font-size: 18px;
    }

    &.active {
      font-weight: bold;
    }
  }

  .dropdown {

    &-menu {
      box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.12);
      padding: 15px;
      border: 0;
      top: calc(100% - 2px);
      left: -10px;
      border-radius: 0;
      background: $white;
      &.show{
        display: block !important;
      }
    }

    &-item {
      position: relative;
      color: $text-color-dark;
      transition: .2s ease;
      font-family: $primary-font;
      padding: 10px;
      text-decoration: none;
      margin-bottom: 0 !important;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: $white;
}