.card{
  border: 0;
  border-radius: 0;
  &-img-top{
    border-radius: 0;
  }
  &-header{
    border: 0;
  }
  &-body{
    border: 0;
    border-radius: 0;
  }
  &-footer{
    border: 0;
  }
}