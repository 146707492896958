.banner {
  min-height: 100vh;
}

.content-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hover-bg-secondary {
  transition: .2s ease;

  * {
    transition: inherit;
  }

  &:hover {
    background: $secondary-color;

    * {
      color: $white;
    }
  }

  &.active {
    background: $secondary-color;

    * {
      color: $white;
    }
  }
}

.hover-bg-primary {
  transition: .2s ease;

  * {
    transition: inherit;
  }

  &:hover {
    background: $primary-color;

    * {
      color: $white;
    }
  }

  &.active {
    background: $primary-color;

    * {
      color: $white;
    }
  }
}

.icon-box {
  height: 100px;
  width: 100px;
  line-height: 100px;
}

.icon-box-sm {
  height: 80px;
  width: 80px;
  line-height: 80px;
}

.icon-watermark {
  position: absolute;
  left: 50%;
  top: 32%;
  transform: translate(-50%, -50%);
  opacity: .1;
  transition: inherit;
}

.card:hover {
  .icon-watermark {
    transform: translate(-50%, -50%) scale(1.5);
  }
}

.bg-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: auto;

  img {
    height: 100%;
    max-width: 100%;
    @include desktop {
      display: none;
    }
    @include tablet {
      display: block;
      height: auto;
    }
  }
}

.hover-shadow {
  &::before {
    transition: .3s ease;
    position: absolute;
    height: 100%;
    box-shadow: 0px 33px 49px 0px rgba(190, 193, 228, 0.31);
    width: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    content: "";
    opacity: 0;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
}

/* pricing */
.bottom-shape {
  position: relative;
  overflow: hidden;

  * {
    position: relative;
    z-index: 1;
  }

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    transform: rotate(40deg);
    background: $primary-gradient;
    left: 0;
    top: 60%;
    opacity: .5;
  }

  &::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    transform: rotate(40deg);
    background: $primary-gradient;
    left: 20%;
    top: 55%;
  }
}

/* /pricing */

/* project */
.project-item {
  position: relative;

  &:hover {
    .project-hover {
      visibility: visible;
      opacity: 1;
      transform: rotateX(0deg);
    }
  }
}

.project-hover {
  position: absolute;
  width: 80%;
  left: 10%;
  bottom: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transform: rotateX(90deg);
  transition: .3s ease;
  transform-origin: bottom;

  i {
    height: 55px;
    width: 55px;
    line-height: 55px;
    border: 1px solid $white;
    border-radius: 50%;
    text-align: center;
    display: block;

    &:hover {
      border-color: $primary-color;
    }
  }
}

/* /project */

/* footer */
footer {
  .overlay-image {
    height: 80%;
    width: 80%;
    left: 10%;
    top: 10%;
  }
}