/* progressbar */
.progress-block {
  .progress {
    height: 8px;
    border-radius: 10px;
    margin-bottom: 40px;
    overflow: visible;
    position: relative;
    box-shadow: 0px 9px 24px 0px rgba(19, 5, 44, 0.21);
  }

  .progress-bar {
    background: $primary-gradient;
    width: 0;
    transition: all 3s ease-in-out;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 10px;

    .skill-number {
      position: absolute;
      top: -22px;
      right: 0px;
    }
  }
}

/* /progressbar */

/* video */
.video-player {
  iframe {
    width: 100%;
    height: 100%;
  }

  .play-icon {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
  }
}


/* ripple animation */
@mixin rings($duration, $delay) {
  opacity: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  content: '';
  height: 100%;
  width: 100%;
  border: 1px solid rgba($color: $border-color, $alpha: 1.0);
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.65, 0, .34, 1);
  z-index: -1;
}

.ripple {
  border-radius: 50%;
  position: absolute;
  height: 130%;
  width: 130%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &::after {
    @include rings(3s, 0s);
  }

  &::before {
    @include rings(3s, 0.5s);
  }
}


@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }

  to {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
  }
}


/* /video */

/* card slider */
.ui-card-slider .left-slide::before {
  @include desktop {
    left: -100px;
  }
  @include tablet {
    display: none;
  }
}
.ui-card-slider .right-slide::before {
  @include desktop {
    right: -100px;
  }
  @include tablet {
    display: none;
  }
}
/* /card slider */

/* google map */
.map{
  height: 750px;
}
/* /google map */

/* team */
.img-thumb-circle{
  border-radius: 50%;
  overflow: hidden;
  max-height: 260px;
  max-width: 260px;
}